import { ByKey } from '../required/ByKey';

// Based on https://github.com/microsoft/TypeScript/issues/30611#issuecomment-479087883
type StandardEnum<T> = {
    [id: string]: T | string;
    [nu: number]: string;
};

export function getEnumArray<T extends string | number>(name: string, enumerate: StandardEnum<T>): T[] {
    const envVar = process.env[name];
    if (!envVar) {
        throw new Error(`Environment variable ${name} is not set.`);
    }
    const enumValues = Object.values(enumerate);
    return JSON.parse(envVar).map((value: T): T => {
        if (!enumValues.includes(value)) {
            throw new Error(`Unknown value '${value}' in environment variable ${name}.`);
        }
        return value;
    });
}

export function getEnumByKey<T extends string | number>(name: string, enumerate: StandardEnum<T>): ByKey<T> {
    const enumValues = Object.values(enumerate);
    return getByKey(name, (_envVar: string, key: string, value: any): T => {
        if (!enumValues.includes(value)) {
            throw new Error(`Unknown value '${value}' for key '${key}' in environment variable ${name}.`);
        }
        return value as T;
    });
}

export function stringTypeCheck(envVar: string, key: string, value: any): string {
    const type = typeof value;
    if (type !== 'string') {
        throw new Error(
            `Expected string value for key '${key}' in environment variable '${envVar}'. Got '${value}' of type '${type}'.`,
        );
    }
    return value;
}

export function getByKey<T>(
    name: string,
    typeCheck: (envVar: string, key: string, value: any) => T,
): ByKey<T> {
    const envVar = process.env[name];
    if (!envVar) {
        throw new Error(`Environment variable ${name} is not set.`);
    }
    const obj: { [key: string]: T } = JSON.parse(envVar);

    return new ByKey(
        Object.fromEntries(
            Object.entries(obj).map(([key, value]): [string, T] => {
                return [key, typeCheck(name, key, value)];
            }),
        ),
    );
}

type EnvVarsSearch = { [key: string]: boolean };
type EnvVarsResult<T> = { [P in keyof T]?: string };

/**
 * Get the environment variables with a given suffix. If an environment variable with suffix is not found,
 * then the last part of the suffix, separated by an underscore, is removed until a value is found.
 * An error is thrown if an environment variable is required and no value was found.
 *
 * The suffixed environment variables are created by concatenating an environment variable, an underscore and
 * (part of) the given suffix in uppercase.
 *
 * An example:
 * Given suffix=a_b and envVars={FOO: false, BAR: true}
 * The function will search for the following environment variables stopping as soon as a value is found:
 * For FOO it will search:
 * - FOO_A_B
 * - FOO_A
 * - FOO
 * For BAR it will search:
 * - BAR_A_B
 * - BAR_A
 * - BAR
 *
 * Since Bar is required, an error will be thrown if no value was found.
 *
 * @param suffix
 * @param envVars is an object where the key is the name of an environment variable (without suffix)
 *  and the value indicates if the environment variable is required or not.
 */
export function getWithSuffix<T extends EnvVarsSearch>(suffix: string, envVars: T): EnvVarsResult<T> {
    const upperCase = suffix.toUpperCase();
    const suffixes = upperCase.split('_');

    const result: EnvVarsResult<T> = {};
    for (const envVar in envVars) {
        const required = envVars[envVar];
        let value: string | undefined;
        for (let i = suffixes.length; i > 0; i--) {
            const searchEnvVar = envVar + '_' + suffixes.slice(0, i).join('_');
            value = process.env[searchEnvVar];
            if (value) {
                break;
            }
        }
        if (!value) {
            value = process.env[envVar];
        }
        if (required && !value) {
            const subsetMessage = suffix === '' ? '' : ` with subset of suffix '_${upperCase}'`;
            throw new Error(`Environment variable '${envVar}'${subsetMessage} must be set.`);
        }
        result[envVar] = value ? value.toString() : undefined;
    }
    return result;
}

export function getEnvVars<T extends EnvVarsSearch>(envVars: T): EnvVarsResult<T> {
    return getWithSuffix<T>('', envVars);
}
