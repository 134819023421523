import type { AppProps, NextWebVitalsMetric } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import { ThemeProvider } from 'next-themes';

import { FlashMessageHolder } from '@admin/molecules/FlashMessage';
import { FontFaces } from '@common/atoms/FontFaces';
import { FallbackStyleBlock } from '@common/atoms/FontFaces/atoms/FallbackStyleBlock';
import { SupertokensWrapper } from '@common/atoms/SupertokensWrapper';
import { PlatformID } from '@common/clients/api';
import { ContextData, isContextData, ResolvedContextData } from '@common/defaults';
import { logger } from '@common/logger';
import { ContextDataProvider } from '@common/useContextData';
import { getConfig } from '@pxr/i18n';

import { defaultNamespaces } from '../namespaces';

import '../styles/index.scss';

function MyApp({ Component, pageProps }: AppProps) {
    const props: any = { ...pageProps };
    const contextData: ContextData | undefined = isContextData(props?.contextData)
        ? props.contextData
        : undefined;

    return (
        <ThemeProvider themes={['admin']} forcedTheme={'admin'}>
            {contextData?.platform.id === PlatformID.VI ? (
                <FallbackStyleBlock />
            ) : (
                <FontFaces platform={contextData?.platform.id || PlatformID.VP} isAdmin={true} />
            )}
            {contextData ? (
                <SupertokensWrapper autoRefresh={true} contextData={contextData}>
                    <ContextDataProvider contextData={contextData as ResolvedContextData}>
                        <Component {...pageProps} />
                        <FlashMessageHolder />
                    </ContextDataProvider>
                </SupertokensWrapper>
            ) : (
                <Component {...pageProps} />
            )}
        </ThemeProvider>
    );
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
    logger.debug(metric);
    if (typeof global.window.gtag !== 'undefined') {
        window.gtag('event', metric.name, {
            event_category: metric.label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
            value: Math.round(metric.name === 'CLS' ? metric.value * 1000 : metric.value),
            event_label: metric.id,
            non_interaction: true,
        });
    }
}

export default appWithTranslation(MyApp, getConfig(defaultNamespaces));
